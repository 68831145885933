import { useCallback } from "react";
import { addRegistrationData } from "../types/registration";
import { addInvoicenumberData } from "../types/invoicenumber";
import { LogMessage } from "../types/logs";

export const useEXCGruppeAPI = () => {
    const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.s144AwmQecP0ZPqW-D3Ls_vbtYqzmZtxguRc5Ggmh24';

    const API_URL = 'https://exc.twitch-duell.com/api/';
    const API_VERSION = 'v1';

    const getMallecupEvents = useCallback(async () => {
        const API_ENDPOINT = `${API_URL}${API_VERSION}/events/`;

        const requestOptions: RequestInit = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TOKEN}`
            },
        };

        return fetch(`${API_ENDPOINT}`, requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (!result.error) {
                    return result;
                }
                console.error('error ' + result.message);
            })
            .catch(error => console.log('error', error)); 
    }, [API_URL, TOKEN]);

    const getMallecupTeamsByEvent = useCallback(async (eventId: number) => {
        //TODO: Implement this function
    }, [API_URL, TOKEN]);

    const getMallecupAccomdationsByEvent = useCallback(async (eventId: number) => {
        const API_ENDPOINT = `${API_URL}${API_VERSION}/accomodations/${eventId}`;

        const requestOptions: RequestInit = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TOKEN}`
            },
        };

        return fetch(`${API_ENDPOINT}`, requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (!result.error) {
                    return result;
                }
                console.error('error ' + result.message);
            })
            .catch(error => console.log('error', error)); 
    }, [API_URL, TOKEN]);

    const getMaxInvoiceNumberForEvent = useCallback(async (eventId: number) => {
        const API_ENDPOINT = `${API_URL}${API_VERSION}/invoiceNumbers/${eventId}`;

        const requestOptions: RequestInit = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TOKEN}`
            },
        };

        return fetch(`${API_ENDPOINT}`, requestOptions)
            .then((response) => response.json())
            .then(result => {
                if (!result.error) {
                    return result;
                }
                console.error('error ' + result.message);
            })
            .catch(error => console.log('error', error));
    }, [API_URL, TOKEN]);

    const addInvoiceNumber = useCallback(async(invoicenumber: addInvoicenumberData) => {
        const API_ENDPOINT = `${API_URL}${API_VERSION}/invoiceNumbers/add`;

        const requestOptions: RequestInit = {
            method: 'POST',
            body: JSON.stringify(invoicenumber),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TOKEN}`
            },
        };

        fetch(`${API_ENDPOINT}`, requestOptions)
        .then(response => {
        if (response.status === 200) {
            console.log('Success');
        } else {
            console.log('Something went wrong');
        }
        return response.json();
        })
        .then(result => {
            if (result.error) {
                console.error('error ', result.message);
                return false;
            }
        })
        .catch(error => console.log('error', error));
        return true;
    }, [API_URL, TOKEN]);

    const addMallecupRegistration =  useCallback(async (registration: addRegistrationData) => {
        const API_ENDPOINT = `${API_URL}${API_VERSION}/registrations/add`;

        const requestOptions: RequestInit = {
            method: 'POST',
            body: JSON.stringify(registration),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TOKEN}`
            },
        };

        fetch(`${API_ENDPOINT}`, requestOptions)
        .then(response => {
        if (response.status === 200) {
            console.log('Success');
        } else {
            console.log('Something went wrong');
        }
        return response.json();
        })
        .then(result => {
            if (result.error) {
                console.error('error ', result.message);
                return false;
            }
        })
        .catch(error => console.log('error', error));
        return true;
    }, [API_URL, TOKEN]);

    const logMessage = useCallback(async (message: LogMessage) => {
        const API_ENDPOINT = `${API_URL}${API_VERSION}/logger/log`;

        const requestOptions: RequestInit = {
            method: 'POST',
            body: JSON.stringify(message),
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TOKEN}`
            },
        };

        fetch(`${API_ENDPOINT}`, requestOptions)
        .then(response => {
        if (response.status === 200) {
            console.log('Success');
        } else {
            console.log('Something went wrong');
        }
        })
        .catch(error => console.log('error', error));
    }, [API_URL, TOKEN]);
    

    return {
        getMallecupEvents,
        getMallecupTeamsByEvent,
        getMallecupAccomdationsByEvent,
        getMaxInvoiceNumberForEvent,
        addInvoiceNumber,
        addMallecupRegistration,
        logMessage
    };
};