import './StepsNavigationButtons.css';
import { useSteps } from '../../../../hooks/useSteps';
import { useRegistration } from '../../../../context/registrationContext';
import { useEXCGruppeAPI } from '../../../../hooks/useApi';
import { setupRegistrationData } from '../../../../utils/helper';
import { addInvoicenumberData } from '../../../../types/invoicenumber';
import { useState } from 'react';
import WarningModal from '../../../common/warningModal/warningModal';

const StepsNavigationButtons = () => {
    // Add your component logic here
    const { nextStep, prevStep, currentStep } = useSteps();
    const { registration } = useRegistration();
    const useEXCGruppeAPIHook = useEXCGruppeAPI();
    const [showWarning, setShowWarning] = useState(false);

    const isContactPersonStepValid = () => {
        return registration.contactPerson.firstname !== '' && 
            registration.contactPerson.lastname !== '' && 
            registration.contactPerson.email !== '' && 
            registration.contactPerson.phone !== '' && 
            registration.contactPerson.street !== '' && 
            registration.contactPerson.streetNumber !== '' && 
            registration.contactPerson.zip.length >= 4 && 
            registration.contactPerson.city !== '' &&
            currentStep === 2;
    }

    const isTeamStepValid = () => {
        return registration.teams.divisions.reduce((sum, team) => sum + team.amount, 0) > 0 && currentStep === 3;
    }

    const isParticipantStepValid = () => {
        return registration.participants.amount > 0 && currentStep === 4;
    }

    const isAccomodationStepValid = () => {
        return registration.accomodations.reduce((sum, accomodation) => sum + accomodation.amount, 0) === +registration.participants.amount && currentStep === 5;
    }

    const isAGBChecked = () => {
        return registration.overview.AGBChecked && currentStep === 6;
    }

    const handleNextClick = async() => {
        if (!isContactPersonStepValid() && !isTeamStepValid() && !isParticipantStepValid() && !isAccomodationStepValid() && !isAGBChecked()) {
            setShowWarning(true);
            return;
        }

        if (isAGBChecked()) {
            const fetchedInvoiceNumber = await useEXCGruppeAPIHook.getMaxInvoiceNumberForEvent(registration.event.id);
            const registrationData = setupRegistrationData(fetchedInvoiceNumber['invoiceNumber'] === 0 ? 1 : fetchedInvoiceNumber['invoiceNumber'], registration);
            if (await useEXCGruppeAPIHook.addMallecupRegistration(registrationData)) {
                const invoiceNumberData: addInvoicenumberData = { year: registration.event.fromDate.getFullYear(), eventId: registration.event.id, invoiceNumber: (fetchedInvoiceNumber['invoiceNumber'] === 0 ? 1 : fetchedInvoiceNumber['invoiceNumber']) + 11, status: 1};
                if (await useEXCGruppeAPIHook.addInvoiceNumber(invoiceNumberData)) {
                    console.log('Invoice number added!');
                    console.log('Registration successful!');
                    useEXCGruppeAPIHook.logMessage({ message: `User entered following information ${JSON.stringify(registration)} in the step ${currentStep} and was redirected to the "Anmeldebestätigung" page`, timestamp: new Date() });
                    setTimeout(() => {
                        window.location.href = 'https://mallecup.com/anmeldebestaetigung/';
                    }, 1000);
                }
                return;
            }
            console.log('Registration failed!');
        } else {
            useEXCGruppeAPIHook.logMessage({ message: `User entered following information ${JSON.stringify(registration)} in the step ${currentStep}`, timestamp: new Date() });
            nextStep();
        }
    };

    const handlePrevClick = () => {
        useEXCGruppeAPIHook.logMessage({ message: `User moved back to the step ${currentStep - 1}`, timestamp: new Date() });
        prevStep();
    };

    return (
        <div className='bodyContainerButtons'>
            <div className='buttonContainer'>
                <button className='bodyContainerButton' onClick={handlePrevClick}>Zurück</button>
                <button 
                    className='bodyContainerButton continueButton' 
                    onClick={handleNextClick}
                    >
                    {currentStep === 6 ? 'Buchen' : 'Weiter'}
                </button>
                <WarningModal 
                    show={showWarning}
                    message="Bitte fülle alle Felder aus!"
                    onClose={() => setShowWarning(false)}
                />
            </div>
        </div>
    );
};

export default StepsNavigationButtons;