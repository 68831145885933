import './App.css';
import { BackgroundImage } from './components/common/backgroundImage/backgroundImage';
import { Body } from './components/layout/Body/Body';
import { Footer } from './components/layout/Footer/Footer';
import { Header } from './components/layout/Header/Header';
import { useEXCGruppeAPI } from './hooks/useApi';
import { useCallback, useEffect } from 'react';
import { useSteps } from './hooks/useSteps';

function App() {
  const useEXCGruppeAPIHook = useEXCGruppeAPI();
  const { currentStep } = useSteps();

  const logUserExit = useCallback(() => {
    let exitMessage = 'User is leaving the website while on step ' + currentStep;

    useEXCGruppeAPIHook.logMessage({ message: exitMessage, timestamp: new Date() });
  }, [useEXCGruppeAPIHook]);

  useEffect(() => {
    let message = 'User visited the website';
    message += ` Screen size: ${window.screen.width}x${window.screen.height}`;
    message += ` Platform: ${navigator.platform}`;
    message += ` Language: ${navigator.language}`;
    message += ` User agent: ${navigator.userAgent}`;
    message += ` Referrer: ${document.referrer}`;
    message += ` Path: ${window.location.pathname}`;
    message += ` Hash: ${window.location.hash}`;
    message += ` Search: ${window.location.search}`;

    useEXCGruppeAPIHook.logMessage({ message: message, timestamp: new Date() });

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', logUserExit);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('beforeunload', logUserExit);
    };
  }, [useEXCGruppeAPIHook, logUserExit]);

  return (
    <div className="App">
      <div className='AppContainer'>
        <Header />
        <Body />
        {/* <Footer /> */}
      </div>
      <BackgroundImage />
    </div>
  );
}

export default App;