import { AccomodationType, CheapestAccomodationType } from "../types/accomodations";
import { addRegistrationData, RegistrationType } from "../types/registration";

export function formatPrice(price: number): string {
    const formattedPrice = (Math.ceil(price * 100) / 100).toFixed(2);
    return formattedPrice.replace(".", ",");
};

export function getWeekday(datetime: Date): string {
    const date = new Date(datetime);
    const dayOfWeek = date.getUTCDay();
  
    const germanAbbreviations = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  
    return germanAbbreviations[dayOfWeek];
};

export function addOneWeek(date: Date): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 7);
    return newDate;
};

export function addTwoWeeks(date: Date): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 14);
    return newDate;
};

export function subtractEightWeeks(date: Date): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - (8 * 7));
    return newDate;
};

export function convertDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
};

export function setupRegistrationData(invoiceNumber: number, registration: RegistrationType): addRegistrationData {
    const currentDate = new Date();
    let cheapestAccomodation: CheapestAccomodationType = {id: '', name: '', price: 0, amount: 0};
    // console.log('accomodations', registration.accomodations);
    registration.accomodations.forEach(accomodation => {
        // console.log('cheapestAccomodation', cheapestAccomodation);
        // console.log('accomodation', accomodation);
        if (cheapestAccomodation.id.length === 0 && accomodation.amount > 0) {
            cheapestAccomodation = accomodation;
        }

        if (cheapestAccomodation.id.length !== 0 && accomodation.amount > 0) {
            // console.log('amount', accomodation.amount);
            if (Number(accomodation.price) < Number(cheapestAccomodation.price)) {
                cheapestAccomodation = accomodation;
            }
        }
    });
    // console.log(cheapestAccomodation);
    const teamsCost: number = registration.teams.divisions.reduce((acc, position) => acc + (position.amount * position.price), 0);
    const accomodationsCost: number = registration.accomodations.reduce((acc, position) => acc + (position.amount * position.price), 0);
    const totalCost: number = teamsCost + accomodationsCost - (currentDate < new Date('2025-01-01') ? (registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) * cheapestAccomodation.price) : 0);

    let teamsOverview = '';
    registration.teams.divisions.forEach(team => {
        if (teamsOverview.length > 0 && team.amount > 0) {
            teamsOverview += `\r\n${team.amount} Team ${team.name.trim()}`;
        } else if (team.amount > 0) {
            teamsOverview += `${team.amount} Team ${team.name.trim()}`;
        }
    });

    let bookingOverview = `Anmeldegebühr für ${registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0)} ${registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) > 1 ? 'Teams' : 'Team'} ${formatPrice(registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) * 100)} €`;
    registration.accomodations.forEach(accomodation => {
        if (accomodation.amount > 0) {
            bookingOverview += `\r\n${accomodation.amount} ${accomodation.amount > 1 ? 'Personen' : 'Person'} in der Kategorie ${accomodation.id.trim()} ${formatPrice(accomodation.amount * accomodation.price)} €`;
        }
    });
    if (currentDate < new Date('2025-01-01')) {
        bookingOverview += `\r\n${registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0)} ${registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) > 1 ? 'Personen' : 'Person'} in der Kategorie Freiplatz ${cheapestAccomodation.id} ${formatPrice((registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) * cheapestAccomodation.price) * -1)} €`
    }
    
    const registrationData: addRegistrationData = {
        registrationDate: convertDate(currentDate),
        invoiceNumber: `${registration.event.fromDate.getFullYear()}-${registration.event.id}-${invoiceNumber + 11}`,
        eventId: registration.event.id,
        eventName: `Malle Cup ${registration.event.name.trim()} ${registration.event.fromDate.getFullYear()}`,
        club: registration.teams.name.trim(),
        overviewTeamCategories: teamsOverview,
        bookingOverview: bookingOverview,
        total: `${formatPrice(totalCost)} €`,
        name: registration.contactPerson.firstname.trim(),
        lastname: registration.contactPerson.lastname.trim(),
        email: registration.contactPerson.email.trim(),
        mobilenumber: registration.contactPerson.phone.trim(),
        street: registration.contactPerson.street.trim(),
        streetNumber: registration.contactPerson.streetNumber.trim(),
        zipCode: registration.contactPerson.zip.trim(),
        city: registration.contactPerson.city.trim(),
        participantAmount: registration.participants.amount,
        furtherInformation: registration.overview.furtherInformation.trim(),
        downPayment: currentDate < subtractEightWeeks(registration.event.fromDate)
            ? (currentDate < new Date('2025-01-01') 
                ? `${formatPrice(teamsCost)} €` 
                : `${formatPrice(teamsCost + (Math.floor((accomodationsCost * 0.2) * 10) / 10))} €`)
            : '',
        downPaymentDate: currentDate < subtractEightWeeks(registration.event.fromDate) 
            ? convertDate(addTwoWeeks(currentDate)) 
            : '',
        secondPayment: (currentDate < new Date('2025-01-01') && currentDate < subtractEightWeeks(registration.event.fromDate))
            ? `${formatPrice(Math.floor(((totalCost - teamsCost) * .2) * 10) / 10)} €`
            : '',
        secondPaymentDate: (currentDate < new Date('2025-01-01') && currentDate < subtractEightWeeks(registration.event.fromDate))
            ? '14.01.2025'
            : '',
        finalPayment: currentDate < subtractEightWeeks(registration.event.fromDate)
            ? `${formatPrice((totalCost - teamsCost - Math.floor(((totalCost - teamsCost) * .2) * 10) / 10))} €`
            : `${formatPrice(totalCost)} €`,
        finalPaymentDate: currentDate < subtractEightWeeks(registration.event.fromDate)
            ? convertDate(subtractEightWeeks(registration.event.fromDate))
            : convertDate(addOneWeek(currentDate)),
        status: 2
    };

    return registrationData;
}