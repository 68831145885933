import './Overview.css';
import React from 'react';
import InputElement from '../../../common/inputElement/InputElement';
import PaymentOverviewElement from '../../../common/paymentOverviewElement/paymentOverviewElement';
import { useRegistration } from '../../../../context/registrationContext';
import { RegistrationType } from '../../../../types/registration';
import { addOneWeek, addTwoWeeks, convertDate, formatPrice, subtractEightWeeks } from '../../../../utils/helper';
import { CheapestAccomodationType } from '../../../../types/accomodations';

const Overview: React.FC = () => {
    const { registration, updateRegistration } = useRegistration();
    const step: keyof RegistrationType = 'overview';
    const currentDate = new Date();
    let cheapestAccomodation: CheapestAccomodationType = {id: '', name: '', price: 0, amount: 0};
    // console.log('accomodations', registration.accomodations);
    registration.accomodations.forEach(accomodation => {
        // console.log('cheapestAccomodation', cheapestAccomodation);
        // console.log('accomodation', accomodation);
        if (cheapestAccomodation.id.length === 0 && accomodation.amount > 0) {
            cheapestAccomodation = accomodation;
        }

        if (cheapestAccomodation.id.length !== 0 && accomodation.amount > 0) {
            // console.log('amount', accomodation.amount);
            if (Number(accomodation.price) < Number(cheapestAccomodation.price)) {
                cheapestAccomodation = accomodation;
            }
        }
    });
    const teamsCost: number = registration.teams.divisions.length > 0 ? registration.teams.divisions.reduce((acc, position) => acc + (position.amount * position.price), 0) : 0;
    const accomodationsCost: number = registration.accomodations.length > 0 ? registration.accomodations.reduce((acc, position) => acc + (position.amount * position.price), 0) : 0;
    const totalCost: number = teamsCost + accomodationsCost - (currentDate < new Date('2025-01-01') ? (registration.teams.divisions.reduce((acc, position) => acc + position.amount, 0) * cheapestAccomodation.price) : 0);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateRegistration({
            [`overview.AGBChecked`]: event.target.checked
        });
    };

    const value = registration[step] && typeof registration[step] === 'object'
        ? (registration[step] as Record<string, any>)['AGBChecked'] || ''
        : '';

    return (
        <div className='width100 stepContent overviewStepContent'>
            <div className='hideOnMobile stepName'>
                Übersicht
            </div>
            <InputElement id="furtherInformation" label='Zusätzliche Informationen' type="multiline" step="overview" />
            {/* <div className='laptopWidth40'>
                <InputElement id="coupon" label="Coupon" type='text' step="overview" />
            </div> */}
            <PaymentOverviewElement 
                header="Anmeldegebühren" 
                positions={registration.teams.divisions}
            />
            <PaymentOverviewElement 
                header="Teilnahmekosten" 
                positions={registration.accomodations}
                cheapestAccomodation={{id: cheapestAccomodation.id, name: `${cheapestAccomodation.name} *`, price: cheapestAccomodation.price, amount: cheapestAccomodation.amount }}
            />
            <div className='paymentOverviewElementContainer'>
            <div className='paymentOverviewElementHeader'>
                Gesamtkosten:
            </div>
                <div className='paymentOverviewElementElementCotainer'>
                    <div className='paymentOverviewElementElementDescription paddingX10'>
                        Gesamt:
                    </div>
                    <div className='paymentOverviewElementElementPrice price-content paddingX10'>
                        {formatPrice(totalCost)} €
                    </div>
                </div>
            </div>
            <div className='paymentOverviewElementContainer'>
                <div className='paymentOverviewElementHeader'>
                    Zahlungsübersicht:
                </div>
                <div className='paymentOverviewElementElements'>
                    {currentDate < subtractEightWeeks(registration.event.fromDate) && 
                        <div key={'Anzahlung'} className='paymentOverviewElementElementCotainer'>
                            <div className='paymentOverviewElementElementDescription text-content'>
                                Anzahlung bis zum {convertDate(addTwoWeeks(currentDate))}
                            </div>
                            <div className='paymentOverviewElementElementPrice price-content'>
                                {currentDate < new Date('2025-01-01') 
                                    ? `${formatPrice(teamsCost)} €` 
                                    : `${formatPrice(teamsCost + (Math.floor((accomodationsCost * 0.2) * 10) / 10))} €`}
                            </div>
                        </div>
                    }
                    {currentDate < new Date('2025-01-01') && currentDate < subtractEightWeeks(registration.event.fromDate) && 
                        <div key={'Zweite Anzahlung'} className='paymentOverviewElementElementCotainer'>
                            <div className='paymentOverviewElementElementDescription text-content'>
                                Anzahlung bis zum 14.01.2025
                            </div>
                            <div className='paymentOverviewElementElementPrice price-content'>
                                {formatPrice(Math.floor(((totalCost - teamsCost) * .2) * 10) / 10)} €
                            </div>
                        </div>
                    }
                    {currentDate < subtractEightWeeks(registration.event.fromDate) && 
                        <div key={'Restzahlung'} className='paymentOverviewElementElementCotainer'>
                            <div className='paymentOverviewElementElementDescription text-content'>
                                Restzahlung bis zum {convertDate(subtractEightWeeks(registration.event.fromDate))}
                            </div>
                            <div className='paymentOverviewElementElementPrice price-content'>
                                {formatPrice((totalCost - teamsCost - Math.floor(((totalCost - teamsCost) * .2) * 10) / 10))} €
                            </div>
                        </div>
                    }
                    {currentDate >= subtractEightWeeks(registration.event.fromDate) &&
                        <div key={'Gesamtzahlung'} className='paymentOverviewElementElementCotainer'>
                            <div className='paymentOverviewElementElementDescription text-content'>
                                Gesamtzahlung bis zum {convertDate(addOneWeek(currentDate))}
                            </div>
                            <div className='paymentOverviewElementElementPrice price-content'>
                                {formatPrice(totalCost)} €
                            </div>
                        </div>
                    }
                </div>
            </div>
            {currentDate < new Date('2025-01-01') && 
                <div className='text-content'>
                    * Bei Anmeldungen vor dem 01.01.2025 übernehmen wir den Trainerschlafplatz!
                </div>
            }
            <div className='AGBLinkElement'>
                <input 
                    className='checkbox'
                    type='checkbox'
                    onChange={handleChange}
                    checked={value}
                    />
                <div className='text-content'>
                    {`Hiermit melde ich mich verbindlich zum Malle Cup an und bestätige die `}
                    <a className='Link' target='tab' href='https://mallecup.com/allgemeine-geschaeftsbedingungen/'>
                        {'AGB'}
                    </a>
                    .
                </div>
            </div>
        </div>
    );
};

export default Overview;